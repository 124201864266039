import playersHistory from "../res/players";
import PlayerTeamIcon from "./PlayerTeamIcon";
// import { maxGuesses } from "../App";
import { removeThisYearsTeam } from "../utils";
import { GameContext } from "./GameProvider";
import { useContext, useEffect, useState } from "react";

function PlayerTeamHistory(props) {
    const gameContext = useContext(GameContext);
    const [revealed, setRevealed] = useState(false);
    const [teams, setTeams] = useState(
        [
            ...removeThisYearsTeam(
                playersHistory[props.player.name].mergedTeams,
                gameContext.currentTeam,
                gameContext.gameMode
            ),
            "=basketball_question_mark",
        ].reverse()
    );

    useEffect(() => {
        if (props.fullHistory && !revealed) {
            setRevealed(true)
            setTeams(
                [
                    ...playersHistory[props.player.name].mergedTeams,
                    "=basketball_question_mark",
                ].reverse()
            );
        }
    }, [props.fullHistory, props.player.name, revealed]);

    return (
        <div key={props.player.name}>
            <div className="player-hints-container">
                <p>
                    {gameContext.displayOptions.positions &&
                        props.player.full_position}
                </p>
                <p>
                    {gameContext.displayOptions.numbers &&
                        `#${props.player.number}`}
                </p>
            </div>
            <div className="player-team-history">
                {teams
                    .slice(0, gameContext.displayOptions.rows)
                    .map((team, index) => {
                        return (
                            <div
                                className="team-icon-container"
                                key={team + "fragment"}
                            >
                                {index !== 0 && (
                                    <img
                                        alt="arrow-up"
                                        src="/arrow-up.svg"
                                        className="team-history-arrow-up"
                                        draggable="false"
                                    />
                                )}
                                <div className="team-icon-and-label">
                                    <PlayerTeamIcon
                                        key={team}
                                        team={team.split("=")[1]}
                                        showSilhouettes={
                                            gameContext.displayOptions
                                                .silhouettes
                                        }
                                        darkSilhouette={
                                            gameContext.displayOptions
                                                .darkSilhouette
                                        }
                                        headshot_url={props.player.headshot_url}
                                    />
                                    {index !== 0 && (
                                        <p className="team-year-label">
                                            {gameContext.displayOptions.years &&
                                                team.split("=")[0]}
                                        </p>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                {gameContext.displayOptions.rows < teams.length && (
                    <p className="more-teams-label">
                        +{teams.length - gameContext.displayOptions.rows}
                    </p>
                )}
            </div>
        </div>
    );
}

export default PlayerTeamHistory;
