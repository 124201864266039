import playersBios from "./res/players_lb1";

export function smartLineupSort(lineup) {
    return lineup.sort((a, b) => {
        // sort in this order: G, G-F, F-G, F, F-C, C-F, C
        const positions = ["G", "G-F", "F-G", "F", "F-C", "C-F", "C"];
        return (
            positions.indexOf(playersBios[a].full_position.split(" ")[0]) -
            positions.indexOf(playersBios[b].full_position.split(" ")[0])
        );
    });
}

export function randomElement(array) {
    return array[Math.floor(Math.random() * array.length)];
}

export function removeThisYearsTeam(mergedTeams, currentTeam, gameMode) {
    if (gameMode === "Team") {
        let lastIndex = -1;
        for (let i = mergedTeams.length - 1; i >= 0; i--) {
            if (mergedTeams[i].includes(currentTeam)) {
                lastIndex = i;
                if (
                    mergedTeams[i - 1] &&
                    mergedTeams[i - 1].includes(currentTeam)
                ) {
                    lastIndex = i - 1;
                }
                break;
            }
        }
        return mergedTeams.slice(0, lastIndex);
    } else {
        //if gameMode === "Player" {
        return mergedTeams.slice(0, -1);
    }
}

export function lastElement(array) {
    return array[array.length - 1];
}
export function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(remainingSeconds)}`;
}

export function padZero(number) {
    return number.toString().padStart(2, "0");
}

export function setSavedGameMode(value) {
    localStorage.setItem("lb3-gameMode", value);
}

export function getSavedGameMode(callback) {
    const gameMode = localStorage.getItem("lb3-gameMode");
    if (gameMode) {
        callback(gameMode);
    }
    return gameMode;
}

export const addToStoredGuessHistory = (guess, mode, day) => {
    const storedDay = JSON.parse(
        localStorage.getItem("lb3-daily-guesses-" + mode.toLowerCase())
    );
    if (storedDay && storedDay.day === day && storedDay.guessHistory) {
        storedDay.guessHistory.push(guess);
        localStorage.setItem(
            "lb3-daily-guesses-" + mode.toLowerCase(),
            JSON.stringify(storedDay)
        );
    } else {
        localStorage.setItem(
            "lb3-daily-guesses-" + mode.toLowerCase(),
            JSON.stringify({ guessHistory: [guess], day })
        );
    }
};

export const getStoredGuessHistory = (mode, day) => {
    const storedDay = JSON.parse(
        localStorage.getItem("lb3-daily-guesses-" + mode.toLowerCase())
    );
    // console.log("stored:", storedDay, storedDay?.day, day);
    if (storedDay && storedDay.day === day && storedDay.guessHistory) {
        return storedDay.guessHistory;
    } else {
        return [];
    }
};

export const shareText = (gameContext) => {
    let output = `Larry Birdle 3: ${
        gameContext.gameType === "Daily"
            ? `Day ${gameContext.day}`
            : "Unlimited"
    }\n${gameContext.gameMode === "Team" ? "👥👥" : "👤"}: `;

    for (let i = 0; i < gameContext.guessHistory.length; i++) {
        output += `❌`;
    }
    if (gameContext.gameState === "win") {
        output += `✅`;
    }

    output += `\n\n${window.location.href}`;

    return output;
};

export function shuffle(arr) {
    arr.sort(() => Math.random() - 0.5);
    return arr;
}
