import "../css/otherGames.css";
import OtherGameLink from "./OtherGameLink";

export const otherGames = [
    {
        name: "Larry Birdle",
        img: "other-games/larry-birdle.png",
        url: "https://www.larrybirdle.com",
        description:
            "Guess the NBA player! Each guess compares the characteristics with the mystery player to get you closer every time.",
    },
    {
        name: "Larry Birdle Legacy",
        img: "other-games/larry-birdle-legacy.png",
        url: "https://legacy.larrybirdle.com",
        description:
            "Guess the NBA Legend! The same beloved classic Larry Birdle game, but with the NBA's Top 75 of All-Time.",
    },
    {
        name: "Larry Birdle 2",
        img: "other-games/larry-birdle-2.png",
        url: "https://www.larrybirdle2.com",
        description:
            "Align the NBA players in a grid in 5 different teams, as well as a 6th team with one player from each of the other teams.",
    },
    {
        name: "Larry Birdle Rapid Fire",
        img: "other-games/larry-birdle-rapid-fire.png",
        url: "https://www.larrybirdle2.com/rapid/rapid",
        description:
            "Put your NBA knowledge to the test with a fast-paced game of NBA player trivia streaks.",
    },
    {
        name: "Swingle",
        img: "other-games/swingle.png",
        url: "https://www.swinglegame.com/old",
        description:
            "Guess the MLB player! Each guess compares the characteristics with the mystery player to get you closer every time.",
    },
    {
        name: "Swingle 2",
        img: "other-games/swingle-2.png",
        url: "https://www.swinglegame.com",
        description:
            "Align the MLB players in a grid in 5 different teams, as well as a 6th team with one player from each of the other teams.",
    },
    {
        name: "Fumble",
        img: "other-games/fumble.png",
        url: "https://www.fumblegame.com",
        description:
            "Guess the NFL player! Each guess compares the characteristics with the mystery player to get you closer every time.",
    },
    {
        name: "LaFleurdle",
        img: "other-games/lafleurdle.png",
        url: "https://www.lafleurdle.com",
        description:
            "Guess the NHL player! Each guess compares the characteristics with the mystery player to get you closer every time.",
    },
];

function OtherGamesBox(props) {
    return (
        <div className="other-games-box-container">
            <div className="other-games-label">Play other sports</div>
            <div className="other-games-box">
                {otherGames.map((game) => {
                    return (
                        <OtherGameLink
                            key={game.name}
                            name={game.name}
                            img={game.img}
                            to={game.url}
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default OtherGamesBox;
