import { NavLink } from "react-router-dom";

function OtherGameLink(props) {
    return (
        <NavLink to={props.to} className="other-game-link">
            <img className="other-game-img" src={props.img} alt={props.name} />
            <p className="other-game-label">{props.name}</p>
        </NavLink>
    );
}

export default OtherGameLink;
