import "../../css/App.css";
import "../../css/fonts.css";
import Header from "../Header";
import GameProvider, { playerOptions } from "../GameProvider";
import { useEffect, useRef, useState } from "react";
import FancyPicker from "../FancyPicker";
import Footer from "../Footer";
import { useLocation } from "react-router-dom";
import { randomElement } from "../../utils";
import { teamMap } from "../../maps";

export const maxGuesses = 4;

function Unlimited() {
    const gameType = "Unlimited";
    const headerRef = useRef(null);
    const footerRef = useRef(null);
    const appRef = useRef(null);
    const location = useLocation();
    const [currentTeam, setCurrentTeam] = useState(
        randomElement(Object.keys(teamMap))
    );
    const [currentPlayer, setCurrentPlayer] = useState(
        randomElement(playerOptions)
    );

    useEffect(() => {
        appRef.current.style.minHeight = `${
            window.innerHeight -
            headerRef.current.clientHeight -
            footerRef.current.clientHeight
        }px`;
    }, [headerRef, footerRef, appRef, location]);

    const [gameMode, setGameMode] = useState("Team");
    return (
        <>
            <Header headerRef={headerRef} />
            <div className="App" ref={appRef}>
                <FancyPicker
                    options={["Team", "Player"]}
                    state={gameMode}
                    setState={setGameMode}
                    prefix="Guess the&nbsp;"
                />
                <GameProvider
                    key={"Team"}
                    gameMode={"Team"}
                    gameType={gameType}
                    currentTeam={currentTeam}
                    setCurrentTeam={setCurrentTeam}
                    currentPlayer={currentPlayer}
                    setCurrentPlayer={setCurrentPlayer}
                    setGameMode={setGameMode}
                    className={gameMode === "Team" ? "" : "hide-game"}
                />
                <GameProvider
                    key={"Player"}
                    gameMode={"Player"}
                    gameType={gameType}
                    currentTeam={currentTeam}
                    setCurrentTeam={setCurrentTeam}
                    currentPlayer={currentPlayer}
                    setCurrentPlayer={setCurrentPlayer}
                    setGameMode={setGameMode}
                    className={gameMode === "Player" ? "" : "hide-game"}
                />
            </div>
            <Footer footerRef={footerRef} />
        </>
    );
}

export default Unlimited;
