import React, {
    createContext,
    useRef,
    useState,
    useEffect,
    useCallback,
    useMemo,
} from "react";
import GuessBoard from "./GuessBoard";
import {
    addToStoredGuessHistory,
    getStoredGuessHistory,
    randomElement,
    smartLineupSort,
} from "../utils";
import { teamMap } from "../maps";
import lineups from "../res/lineups";
import playerHistories from "../res/players.json";
import GameCycleDisplay from "./GameCycleDisplay";

export const currentSeasonEnd = "24";
export const GameContext = createContext();

export const playerOptions = Object.keys(playerHistories).filter(
    (player) => playerHistories[player].mergedTeams.length > 1
);
export const teamOptions = Object.values(teamMap).map((e) => e.name);
export const teamIndexOptions = [1, 2, 3];

function GameProvider(props) {
    // console.log(props.currentTeam, lineups[teamMap[props.currentTeam].name]);
    const guessButtonRef = useRef(null);

    const [guessHistory, setGuessHistory] = useState([]);
    const [guessesMade, setGuessesMade] = useState(0);
    const [gameState, setGameState] = useState("playing");

    const [guessValue, setGuessValue] = useState("");
    const [canReplay, setCanReplay] = useState(true);
    const [displayOptions, setDisplayOptions] = useState({
        rows: 2,
        positions: false,
        years: false,
        silhouette: false,
        darkSilhouette: true,
        numbers: false,
        allowReveal: false
    });

    const currentLineup = () => {
        return smartLineupSort(
            lineups[
                teamMap[props.currentTeam].name + "_" + props.currentTeamIndex
            ]
        );
    };

    const clearInput = useCallback(() => {
        setGuessValue("");
        setTimeout(() => {
            if (guessButtonRef.current) {
                guessButtonRef.current.classList.add("disabled");
                // console.log(guessButtonRef.current.classList);
            }
        }, 1);
        [document.querySelectorAll("autocomplete-me")].forEach(
            (e) => (e.value = "")
        );
    }, [setGuessValue, guessButtonRef]);

    const completeBoard = useCallback(() => {
        setDisplayOptions((prevDisplayOptions) => {
            return {
                ...prevDisplayOptions,
                rows: undefined,
                positions: true,
                years: true,
                silhouettes: true,
                darkSilhouette: false,
                numbers: true,
                allowReveal: false
            };
        });
    }, [setDisplayOptions]);

    const gameOver = useCallback(
        (winner) => {
            completeBoard();

            setGameState(winner ? "win" : "lose");
        },
        [completeBoard, setGameState]
    );

    const resetBoard = () => {
        setGuessesMade(0);
        setGuessValue("");
        setGuessHistory([]);
        setGameState("playing");

        if (props.gameType === "Unlimited") {
            setDisplayOptions({
                rows: 2,
                positions: false,
                years: false,
                silhouettes: false,
                darkSilhouette: true,
                numbers: false,
                allowReveal: false
            });
            props.setCurrentTeam(randomElement(Object.keys(teamMap)));
            props.setCurrentTeamIndex(randomElement(teamIndexOptions));
            props.setCurrentPlayer(randomElement(playerOptions));
        }
        clearInput();
    };

    const updateBoardOnIncorrectGuess = useCallback(
        (value, numGuesses) => {
            setGuessHistory((prevGuessHistory) => [...prevGuessHistory, value]);
            setDisplayOptions((prevDisplayOptions) => {
                console.log("numGuesses", numGuesses, props.gameMode);
                if (numGuesses === 0) {
                    return {
                        ...prevDisplayOptions,
                        rows: 3,
                        positions: true,
                    };
                } else if (numGuesses === 1) {
                    return {
                        ...prevDisplayOptions,
                        years: true,
                        numbers: true,
                        rows: undefined,
                        allowReveal: true
                    };
                } else if (numGuesses === 2) {
                    return {
                        ...prevDisplayOptions,
                        silhouettes: true,
                    };
                } else {
                    gameOver(false);
                    return prevDisplayOptions;
                }
            });
        },
        [setGuessHistory, setDisplayOptions, gameOver, props.gameMode]
    );

    // base it on current guess total, not new guess total
    // because setGuessesMade does not update it right away
    //             After each guess
    // 0: first team
    // 1: second team, positions
    // 2: all teams, years, numbers
    // 3: silhouettes
    // 4: game over
    const makeGuess = useCallback(
        (value, replaying = false) => {
            if (value.length === 0) {
                return;
            }

            if (props.gameMode === "Team") {
                if (!teamOptions.includes(value)) return;

                if (!replaying && props.gameType === "Daily") {
                    addToStoredGuessHistory(value, props.gameMode, props.day);
                }

                // valid
                setGuessesMade((prevGuessesMade) => {
                    if (value === teamMap[props.currentTeam].name) {
                        // correct
                        // setGuessHistory((prevGuessHistory) => [
                        //     ...prevGuessHistory,
                        //     value,
                        // ]);
                        gameOver(true);
                    } else {
                        // incorrect
                        updateBoardOnIncorrectGuess(value, prevGuessesMade);
                    }
                    return prevGuessesMade + 1;
                });
                clearInput();
            } else if (props.gameMode === "Player") {
                if (!playerOptions.includes(value)) return;

                if (!replaying && props.gameType === "Daily") {
                    addToStoredGuessHistory(value, props.gameMode, props.day);
                }

                // valid
                setGuessesMade((prevGuessesMade) => {
                    if (value === props.currentPlayer) {
                        // correct
                        // setGuessHistory((prevGuessHistory) => [
                        //     ...prevGuessHistory,
                        //     value,
                        // ]);
                        gameOver(true);
                    } else {
                        // incorrect
                        updateBoardOnIncorrectGuess(value, prevGuessesMade);
                    }
                    return prevGuessesMade + 1;
                });
                clearInput();
            }
        },
        [
            props.gameMode,
            props.currentTeam,
            gameOver,
            updateBoardOnIncorrectGuess,
            clearInput,
            props.currentPlayer,
            props.day,
            props.gameType,
        ]
    );

    useEffect(() => {
        if (
            props.gameType === "Daily" &&
            gameState === "playing" &&
            canReplay &&
            props.day
        ) {
            let guesses = getStoredGuessHistory(props.gameMode, props.day);
            // if (guesses.length > 0) {
            for (let guess of guesses) {
                makeGuess(guess, true);
            }
            setCanReplay(false);
            // }
        } else {
            // console.log("didn't run");
        }
    }, [
        canReplay,
        gameState,
        makeGuess,
        props.day,
        props.gameMode,
        props.gameType,
    ]);

    // enter key to new game

    // useEffect(() => {
    //     const handleKeyDown = (event) => {
    //         if (event.key === "Enter") {
    //             if (gameState === "playing") {
    //                 guessButtonRef.current.click();
    //             } else {
    //                 const newGameButton = document.querySelector(
    //                     `.game-cycle-display.${gameState} .new-game-button`
    //                 );
    //                 if (newGameButton) {
    //                     newGameButton.click();
    //                 }
    //             }
    //         }
    //     };

    //     // Add event listener
    //     document.addEventListener("keydown", handleKeyDown);

    //     // Cleanup function to remove the event listener
    //     return () => {
    //         document.removeEventListener("keydown", handleKeyDown);
    //     };
    // }, [props.gameMode, gameState]);

    const contextValue = useMemo(
        () => ({
            displayOptions,
            setDisplayOptions,
            currentTeam: props.currentTeam,
            currentTeamIndex: props.currentTeamIndex,
            currentPlayer: props.currentPlayer,
            gameState,
            gameMode: props.gameMode,
            setGameMode: props.setGameMode,
            makeGuess,
            guessesMade,
            guessHistory,
            gameType: props.gameType,
            day: props.day,
        }),
        [
            displayOptions,
            setDisplayOptions,
            props.currentTeam,
            props.currentPlayer,
            gameState,
            props.gameMode,
            props.setGameMode,
            makeGuess,
            guessesMade,
            guessHistory,
            props.gameType,
            props.day,
            props.currentTeamIndex,
        ]
    );

    return (
        <GameContext.Provider value={contextValue}>
            <div className={"game-body " + props.className}>
                {/* other games  */}

                <GameCycleDisplay
                    guessValue={guessValue}
                    setGuessValue={setGuessValue}
                    gameState={gameState}
                    resetBoard={resetBoard}
                    guessButtonRef={guessButtonRef}
                />

                <GuessBoard
                    currentLineup={currentLineup}
                    currentPlayer={props.currentPlayer}
                    gameMode={props.gameMode}
                />
            </div>
        </GameContext.Provider>
    );
}

export default GameProvider;
