import "./css/Mobile.css";
import "./css/App.css";
import "./css/fonts.css";
import React, {
    createContext,
    useState,
    useMemo,
    useEffect,
    useRef,
} from "react";
import ReactDOM from "react-dom/client";
// import Unlimited from "./components/archive/Unlimited";
// import Daily from "./components/archive/Daily";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./components/App";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Helmet } from "react-helmet";
import ContactPage from "./components/Contact";
import AboutPage from "./components/AboutPage";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage";

export const AppContext = createContext();

// Renamed functional component to RootComponent to avoid conflict with the root variable
const RootComponent = () => {
    const [gameMode, setGameMode] = useState("Team");
    const headerRef = useRef(null);
    const footerRef = useRef(null);
    const [showTutorial, setShowTutorial] = useState(false);

    const contextValue = useMemo(() => {
        const gameModeOptions = ["Team", "Player"];
        return {
            gameModeOptions,
            gameMode,
            setGameMode,
            setShowTutorial,
            headerRef,
            footerRef,
        };
    }, [gameMode, setGameMode]);

    // useEffect(() => {
    //     window.ezstandalone = window.ezstandalone || {};
    //     window.ezstandalone.cmd = window.ezstandalone.cmd || [];
    //     window.ezstandalone.cmd.push(function () {
    //         window.ezstandalone.define(101, 102, 103, 104);
    //         window.ezstandalone.enable();
    //         window.ezstandalone.display();
    //         console.log("EZ Standalone initialized successfully!");
    //     });
    // }, []);

    return (
        <React.StrictMode>
            <Helmet>
                {/* <script async src="//www.ezojs.com/ezoic/sa.min.js"></script> */}
                <script
                    async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8255221704339624"
                    crossorigin="anonymous"
                ></script>
                <meta
                    name="google-adsense-account"
                    content="ca-pub-8255221704339624"
                />
            </Helmet>
            <BrowserRouter>
                <AppContext.Provider value={contextValue}>
                    <Header
                        headerRef={headerRef}
                        setShowTutorial={setShowTutorial}
                    />
                    <Routes>
                        <Route
                            path="/contact"
                            element={<ContactPage key="Contact" />}
                        />
                        <Route
                            path="/about"
                            element={<AboutPage key="About" />}
                        />
                        <Route
                            path="/privacy-policy"
                            element={<PrivacyPolicyPage key="PrivacyPolicy" />}
                        />
                        <Route
                            path="*"
                            element={<App key="Daily" gameType="Daily" />}
                        />
                        <Route
                            path="/unlimited"
                            element={
                                <App key="Unlimited" gameType="Unlimited" />
                            }
                        />
                    </Routes>
                    <Footer footerRef={footerRef} />
                </AppContext.Provider>
            </BrowserRouter>
        </React.StrictMode>
    );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RootComponent />);
