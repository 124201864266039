import "../css/footer.css";
import AdUnit from "./AdUnit";
import OtherGamesBox from "./OtherGamesBox";
function Footer(props) {
    return (
        <footer ref={props.footerRef}>
            <div className="footer-by">
                <span>
                    By{" "}
                    <a href="https://www.jacobtepperman.com">Jacob Tepperman</a>
                </span>
            </div>
            <div className="footer-twitter">
                <a href="https://www.x.com/larrybirdle">@LarryBirdle</a>
            </div>
            <OtherGamesBox />
            <AdUnit id={105} row={true} />
        </footer>
    );
}

export default Footer;
