import "../css/otherGames.css";

import { Link } from "react-router-dom";
import { otherGames } from "./OtherGamesBox";

function OtherGamesDetailedList(props) {
    return (
        <>
            <h2 className="other-games-detailed-pre-title">
                Other Games by me
            </h2>
            <div className="other-games-detailed-list-container">
                {otherGames.map((game) => {
                    return (
                        <div
                            className="other-game-detailed-item"
                            key={game.name}
                        >
                            <Link to={game.url}>
                                <img
                                    src={game.img}
                                    className="other-game-detailed-img"
                                    alt={game.name}
                                />
                            </Link>
                            <h3 className="other-game-detailed-name">
                                <Link to={game.url}>{game.name}</Link>
                            </h3>
                            <p className="other-game-detailed-details">
                                {game.description}
                            </p>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export default OtherGamesDetailedList;
