function DataWarning(props) {
    return (
        <div>
            <p className="data-warning">
                {props.gameMode === "Team"
                    ? "Each team's top 3 most used starting lineups from the 2023-24 season"
                    : "Only players who have played for multiple teams are included"}
            </p>
        </div>
    );
}

export default DataWarning;
