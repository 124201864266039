import "../css/tutorial.css";
function TutorialButton(props) {
    return (
        <div className="tutorial-button-container vert-cent">
            <button
                className="tutorial-button fancy-text-shadow-small"
                onClick={() => {
                    if (document.querySelector(".tutorial-popover")) {
                        document
                            .querySelector(".tutorial-popover")
                            .scrollIntoView({ behavior: "smooth" });
                    } else {
                        window.location.href = "/about";
                    }
                }}
            >
                ?
            </button>
        </div>
    );
}

export default TutorialButton;
