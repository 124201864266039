import { useCallback, useEffect, useRef, useState } from "react";
import "../css/fancyPicker.css";
import { getSavedGameMode, setSavedGameMode } from "../utils";

function FancyPicker(props) {
    const pickerRef = useRef(null);
    const prefixRef = useRef(null);
    const [canClickOption, setCanClickOption] = useState(true);
    const [optionsOffset, setOptionsOffset] = useState(0);
    const optionsOffsetRef = useRef(optionsOffset);

    // Update the ref whenever optionsOffset changes
    useEffect(() => {
        optionsOffsetRef.current = optionsOffset;
    }, [optionsOffset]);

    const setOptionsOffsetFromElement = useCallback(
        (element) => {
            const optionHeightDifference =
                element.getBoundingClientRect().top -
                prefixRef.current.getBoundingClientRect().top +
                optionsOffsetRef.current; // Use ref here

            setOptionsOffset(optionHeightDifference);
            pickerRef.current.style.setProperty(
                "--options-offset",
                `${-1 * optionHeightDifference}px`
            );
        },
        // Remove optionsOffset from dependencies to avoid infinite loop
        [prefixRef]
    );

    const handleOptionClick = (e) => {
        if (!canClickOption) {
            return;
        }
        setCanClickOption(false);
        setTimeout(() => {
            setCanClickOption(true);
        }, 500);

        setSavedGameMode(e.target.innerText);
        setOptionsOffsetFromElement(e.target);

        // remove active class from all options
        // add active class to clicked option
        props.setState(e.target.innerText);
    };

    useEffect(() => {
        if (canClickOption) {
            // this means the state changed without clicking an option
            // so it was done by the guess the ____ button

            const selectedOption = pickerRef.current.querySelector(
                ".fancy-option.selected"
            );

            setOptionsOffsetFromElement(selectedOption);
        }
    }, [props.state, canClickOption, setOptionsOffsetFromElement]);

    const setPickerHeight = () => {
        const prefixToBottom =
            pickerRef.current.getBoundingClientRect().bottom -
            prefixRef.current.getBoundingClientRect().bottom;

        pickerRef.current.style.setProperty(
            "--picker-top-padding",
            `${prefixToBottom / 2}px`
        );
    };

    useEffect(() => {
        setPickerHeight();
        getSavedGameMode((gameMode) => {
            props.setState(gameMode);
        });
    }, [props]);

    return (
        <div className="fancy-picker" ref={pickerRef}>
            <div className="" ref={prefixRef}>
                {props.prefix}
            </div>
            <div className="options-container">
                {props.options.map((option, index) => {
                    return (
                        <button
                            className={`fancy-option ${
                                props.state === option ? "selected" : ""
                            }`}
                            key={option}
                            onClick={handleOptionClick}
                        >
                            {option}
                        </button>
                    );
                })}
            </div>
        </div>
    );
}

export default FancyPicker;
