import GameProvider, { teamIndexOptions, playerOptions } from "./GameProvider";
import { useContext, useEffect, useRef, useState } from "react";
import DailyCounter from "./DailyCounter";
import FancyPicker from "./FancyPicker";
import { formatTime } from "../utils";
import { randomElement } from "../utils";
import { useLocation } from "react-router-dom";
import { teamMap } from "../maps";

import { AppContext } from "../index";
import OtherGamesBox from "./OtherGamesBox";
import AdUnit from "./AdUnit";
import TutorialPopover from "./TutorialPopover";
import OtherGamesDetailedList from "./OtherGamesDetailedList";

function App(props) {
    const appContext = useContext(AppContext);
    let countdownInterval = useRef(null);

    const appRef = useRef(null);
    const location = useLocation();
    const [currentTeam, setCurrentTeam] = useState(
        randomElement(Object.keys(teamMap))
    );
    const [currentPlayer, setCurrentPlayer] = useState(
        randomElement(playerOptions)
    );
    const [currentTeamIndex, setCurrentTeamIndex] = useState(
        randomElement(teamIndexOptions)
    );

    const [dailyCountdown, setDailyCountdown] = useState("Loading...");
    const [dailyDay, setDailyDay] = useState(undefined);

    useEffect(() => {
        appRef.current.style.minHeight = `${
            window.innerHeight -
            appContext.headerRef.current.clientHeight -
            appContext.footerRef.current.clientHeight
        }px`;
    }, [appContext.headerRef, appContext.footerRef, appRef, location]);

    useEffect(() => {
        if (props.gameType === "Daily") {
            // console.log(prefix + "/.netlify/functions/daily");
            fetch("https://larrybirdle3.netlify.app/.netlify/functions/daily")
                // fetch("http://localhost:9999/.netlify/functions/daily")
                .then((response) => response.json())
                .then((data) => {
                    // console.log(currentPlayer, currentTeam);
                    // console.log(data);
                    setCurrentPlayer(data.player);
                    setCurrentTeam(data.team.split("_")[0]);
                    setCurrentTeamIndex(data.team.split("_")[1]);

                    countdownInterval.current = setInterval(() => {
                        setDailyCountdown(
                            formatTime(
                                Math.max(
                                    Math.floor(
                                        (data.midnight - Date.now()) / 1000
                                    ),
                                    0
                                )
                            )
                        );
                    }, 1000);

                    setDailyDay(data.day);
                })
                .catch((error) => {
                    console.error("Error fetching daily data:", error);
                });
            return clearInterval(countdownInterval.current);
        }
    }, [currentPlayer, currentTeam, currentTeamIndex, props.gameType]);

    useEffect(() => {
        if (localStorage.getItem("lb3-tutorial") !== "true") {
            appContext.setShowTutorial(true);
            localStorage.setItem("lb3-tutorial", "true");
        }
    }, []);

    return (
        <div className="ads-around">
            <AdUnit id={103} col={true} />
            <div className="App" ref={appRef}>
                <OtherGamesBox />
                {props.gameType === "Daily" && (
                    <DailyCounter countdown={dailyCountdown} day={dailyDay} />
                )}
                <FancyPicker
                    options={appContext.gameModeOptions}
                    state={appContext.gameMode}
                    setState={appContext.setGameMode}
                    prefix="Guess the&nbsp;"
                />
                {appContext.gameModeOptions.map((modeOption) => {
                    return (
                        <GameProvider
                            key={modeOption}
                            gameMode={modeOption}
                            setGameMode={appContext.setGameMode}
                            gameType={props.gameType}
                            currentTeam={currentTeam}
                            setCurrentTeam={setCurrentTeam}
                            currentTeamIndex={currentTeamIndex}
                            setCurrentTeamIndex={setCurrentTeamIndex}
                            currentPlayer={currentPlayer}
                            setCurrentPlayer={setCurrentPlayer}
                            className={
                                appContext.gameMode === modeOption
                                    ? ""
                                    : "hide-game"
                            }
                            day={dailyDay}
                        />
                    );
                })}
                <TutorialPopover
                    setShowTutorial={appContext.setShowTutorial}
                    gameMode={appContext.gameMode}
                />
                <AdUnit id={106} row={true} />
                <OtherGamesDetailedList />
            </div>
            <AdUnit id={104} col={true} />
        </div>
    );
}

export default App;
