import { useState } from "react";
import "../css/tutorial.css";
import DataWarning from "./DataWarning";
function TutorialPopover(props) {
    const [currentTutorialPage, setCurrentTutorialPage] = useState(0);

    const handleContainerClick = (e) => {
        // if (e.target.classList.contains("tutorial-popover-container"))
        //     props.setShowTutorial(false);
    };
    return (
        <div
            className="tutorial-popover-container"
            onClick={handleContainerClick}
        >
            <div className="tutorial-popover">
                <div className="tutorial-popover-header">
                    <h1 className="tutorial-popover-title fancy-text-shadow">
                        How to play
                    </h1>
                    {/* <button
                        className="tutorial-popover-close tutorial-popover-close"
                        onClick={() => props.setShowTutorial(false)}
                    >
                        X
                    </button> */}
                </div>
                <div
                    className={`tutorial-popover-body ${props.gameMode}-tutorial`}
                >
                    {currentTutorialPage === 0 && (
                        <div className="tutorial-section tutorial-first-guess-section">
                            <h3>
                                Guess the{" "}
                                {props.gameMode === "Player"
                                    ? "Player"
                                    : "Lineup"}{" "}
                                by their previous teams
                            </h3>
                            <DataWarning gameMode={props.gameMode} />

                            <img
                                src={`tutorial/${props.gameMode}-first-guess.png`}
                                alt=""
                                className="tutorial-img tutorial-first-guess"
                            />
                        </div>
                    )}

                    {currentTutorialPage === 1 && (
                        <div className="tutorial-section tutorial-final-guess-section">
                            <h3>
                                Keep guessing to get more hints (full team
                                history, position, current number, and
                                silhouette)
                            </h3>
                            <img
                                src={`tutorial/${props.gameMode}-final-guess.png`}
                                alt=""
                                className="tutorial-img tutorial-final-guess"
                            />
                        </div>
                    )}
                    {currentTutorialPage === 2 && (
                        <div className="tutorial-section tutorial-guess-button-section">
                            <h3>
                                Click on "Player" or "Team" to change game modes
                            </h3>
                            <img
                                src={`/tutorial/${props.gameMode}-guess-the.png`}
                                alt=""
                                srcset=""
                                className="tutorial-img tutorial-guess-button"
                            />
                        </div>
                    )}
                </div>
                <div className="grow-spacer"></div>
                <div className="tutorial-popover-footer">
                    <div className="tutorial-popover-dots">
                        <div
                            className={`tutorial-popover-dot ${
                                currentTutorialPage === 0 ? "active" : ""
                            }`}
                        ></div>
                        <div
                            className={`tutorial-popover-dot ${
                                currentTutorialPage === 1 ? "active" : ""
                            }`}
                        ></div>
                        <div
                            className={`tutorial-popover-dot ${
                                currentTutorialPage === 2 ? "active" : ""
                            }`}
                        ></div>
                    </div>
                    <div className="tutorial-footer-buttons-container">
                        <button
                            className="tutorial-footer-button previous"
                            disabled={currentTutorialPage === 0}
                            onClick={() =>
                                setCurrentTutorialPage(currentTutorialPage - 1)
                            }
                        >
                            {"\u2190"}
                        </button>
                        <button
                            className="tutorial-footer-button next"
                            disabled={currentTutorialPage === 2}
                            onClick={() =>
                                setCurrentTutorialPage(currentTutorialPage + 1)
                            }
                        >
                            {"\u2192"}
                        </button>

                        <button
                            className="tutorial-footer-button play"
                            // onClick={() => props.setShowTutorial(false)}
                            onClick={() => {
                                if (document.querySelector(".fancy-picker"))
                                    document
                                        .querySelector(".fancy-picker")
                                        .scrollIntoView({ behavior: "smooth" });
                                else if (
                                    document.querySelector(
                                        `${props.gameMode.toLowerCase()}.-link`
                                    )
                                )
                                    document
                                        .querySelector(
                                            `${props.gameMode.toLowerCase()}.-link`
                                        )
                                        .click();
                            }}
                        >
                            Play!
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TutorialPopover;
