import "../css/board.css";
import PlayerTeamHistory from "./PlayerTeamHistory";
import playersBios from "../res/players_lb1";
import { useContext, useState } from "react";
import { GameContext } from "./GameProvider";

function GuessBoard(props) {
    const gameContext = useContext(GameContext)
    const lineup =
        props.gameMode === "Team"
            ? props.currentLineup()
            : [props.currentPlayer];
    const [fullHistory, setFullHistory] = useState(false);

    const handleReveal = () => {
        setFullHistory(true)
    }
    return (
        <div
            className={
                "guess-board " + props.gameMode.toLowerCase() + "-guess-board"
            }
        >
            {(!fullHistory && gameContext.displayOptions.allowReveal && props.gameMode === "Player") && (
                <button className="reveal-button" onClick={handleReveal}>
                    Reveal current team
                </button>
            )}
            {lineup.map((player) => {
                return (
                    <PlayerTeamHistory
                        player={
                            playersBios[player] || {
                                name: player,
                                full_position: "",
                                number: 0,
                                headshot_url: "",
                            }
                        }
                        fullHistory={fullHistory}
                        key={player}
                    />
                );
            })}
        </div>
    );
}

export default GuessBoard;
