import "../css/contact.css";

function ContactPage(props) {
    return (
        <div className="contact-container">
            <h1>Contact</h1>
            <form
                name="contact"
                netlify
                method="POST"
                netlify-honeypot="bot-field"
                className="contact-form"
            >
                <p className="hidden">
                    <label>
                        Don’t fill this out if you’re human:{" "}
                        <input name="bot-field" />
                    </label>
                </p>
                <p>
                    <label>
                        Name{" "}
                        <input
                            type="text"
                            placeholder="Your name here..."
                            name="name"
                        />
                    </label>
                </p>
                <p>
                    <label>
                        Email *
                        <input
                            required
                            type="email"
                            placeholder="Larry@email.com..."
                            name="email"
                        />
                    </label>
                </p>
                <p>
                    <label>
                        Subject *
                        <select name="subject">
                            <option value="Incorrect data">
                                Incorrect data
                            </option>
                            <option value="Bug report">Bug report</option>
                            <option value="Feature request">
                                Feature request
                            </option>
                            <option value="Appreciation">Appreciation</option>
                            <option value="General inquiry">
                                General inquiry
                            </option>
                        </select>
                    </label>
                </p>
                <p>
                    <label>
                        Message *
                        <textarea
                            required
                            type="email"
                            placeholder="Your message here..."
                            name="email"
                        />
                    </label>
                </p>
                <p>
                    <button type="submit" className="contact-submit">
                        Send
                    </button>
                </p>
            </form>
            <div className="grow-spacer"></div>
        </div>
    );
}

export default ContactPage;
