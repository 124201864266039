import { useEffect } from "react";
import "../css/header.css";
import logo from "../res/logo.png";
import { NavLink, useLocation } from "react-router-dom";
import TutorialButton from "./TutorialButton";

function Header(props) {
    // const setActiveOffset = () => {
    //     if (!document.querySelector(".header-item.active")) return;

    //     const activeBox = document
    //         .querySelector(".header-item.active")
    //         .getBoundingClientRect();
    //     const indicator = document.querySelector(".header-active-indicator");
    //     // indicator.left: active.bb.right() - active.bb.left()
    //     const parentBox = document
    //         .querySelector(".header-content")
    //         .getBoundingClientRect();
    //     indicator.style.left = `${
    //         activeBox.right - parentBox.left - activeBox.width / 2
    //         //activeBox.width / 2 + activeBox.left - parentBox.left
    //     }px`;
    // };
    // const location = useLocation();

    // useEffect(() => {
    //     setActiveOffset();
    //     setTimeout(() => {
    //         setActiveOffset();
    //     }, 1000);
    // }, [location]);

    return (
        <header ref={props.headerRef}>
            <div className="header-branding">
                <img
                    src={logo}
                    alt="Larry Birdle 3 logo"
                    className="header-logo"
                    draggable="false"
                />
                <h1 className="header-title fancy-text-shadow vert-cent">
                    Larry Birdle 3
                </h1>
                <TutorialButton setShowTutorial={props.setShowTutorial} />
            </div>

            <div className="header-rest">
                {/* <div className="header-spacer"></div> */}
                <div className="header-content vert-cent">
                    <NavLink
                        to="/"
                        className="header-item fancy-text-shadow-small daily-link"
                    >
                        Daily
                        <div className="header-active-indicator"></div>
                    </NavLink>
                    <NavLink
                        to="/unlimited"
                        className="header-item fancy-text-shadow-small unlimited-link"
                    >
                        Unlimited
                        <div className="header-active-indicator"></div>
                    </NavLink>
                </div>
                <div className="grow-spacer"></div>
                <div className="header-content">
                    <div className="header-vertical-items-container">
                        <NavLink
                            to="/contact"
                            className="header-vertical-item fancy-text-shadow-tiny"
                        >
                            Contact
                        </NavLink>
                        <NavLink
                            to="/about"
                            className="header-vertical-item fancy-text-shadow-tiny"
                        >
                            About
                        </NavLink>
                        <NavLink
                            to="/privacy-policy"
                            className="header-vertical-item fancy-text-shadow-tiny"
                        >
                            Privacy Policy
                        </NavLink>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
