import "../css/ads.css";
function AdUnit(props) {
    return (
        <div
            id={"ezoic-pub-ad-placeholder-" + props.id}
            className={`ads ${props.row ? "row" : ""} ${
                props.col ? "col" : ""
            }`}
        >
            {/* Your content here */}
        </div>
    );
}

export default AdUnit;
