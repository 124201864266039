import { Link } from "react-router-dom";
import "../css/about.css";
import TutorialPopover from "./TutorialPopover";

function AboutPage(props) {
    return (
        <div className="about-container">
            <div className="about-section">
                <h1>What is Larry Birdle 3?</h1>
                <p>
                    Larry Birdle 3 is the third major installment in a series of
                    online basketball trivia games. The previous 2 big games are{" "}
                    <a href="https://www.larrybirdle.com">Larry Birdle</a>, and{" "}
                    <a href="https://www.larrybirdle2.com">Larry Birdle 2</a>.
                    There are also similar games for other sports, including{" "}
                    <a href="https://www.fumblegame.com">American Football</a>,{" "}
                    <a href="https://www.swinglegame.com">Baseball</a>, and{" "}
                    <a href="https://www.lafleurdle.com">Hockey</a>.
                </p>
            </div>

            <div className="about-section">
                <TutorialPopover gameMode={"player"} />
                <br />
                <br />
                <br />
                <br />
            </div>

            <div className="about-section">
                <h1>Who made Larry Birdle 3?</h1>
                <p>
                    Larry Birdle 3, just like all the games linked on this site,
                    was made by{" "}
                    <a href="https://www.jacobtepperman.com/">
                        Jacob Tepperman
                    </a>
                    . Jacob is a computer engineer who enjoys software
                    development, sports, and trivia, so he decided to mix all of
                    that together and start building great games for people who
                    share those same interests.
                </p>
            </div>

            <br />
            <br />
            <br />
            <div className="about-section">
                <h1>How do I send feedback to the developer?</h1>
                <p>
                    Users can send feedback, bug reports, feature requests, or
                    any type of message to the developer at the{" "}
                    <Link to={"/contact"}>contact page</Link>.
                </p>
            </div>
        </div>
    );
}

export default AboutPage;
