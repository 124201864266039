export const teamMap = {
    BOS: {
        name: "Boston Celtics",
        conference: "Eastern",
        division: "Atlantic",
        primary: "#007a33",
        secondary: "#ba9653",
    },
    BKN: {
        name: "Brooklyn Nets",
        conference: "Eastern",
        division: "Atlantic",
        primary: "#000",
        secondary: "#000",
    },
    NYK: {
        name: "New York Knicks",
        conference: "Eastern",
        division: "Atlantic",
        primary: "#006bb6",
        secondary: "#f58426",
    },
    PHI: {
        name: "Philadelphia 76ers",
        conference: "Eastern",
        division: "Atlantic",
        primary: "#006bb6",
        secondary: "#ed174c",
    },
    TOR: {
        name: "Toronto Raptors",
        conference: "Eastern",
        division: "Atlantic",
        primary: "#ce1141",
        secondary: "#000",
    },
    CHI: {
        name: "Chicago Bulls",
        conference: "Eastern",
        division: "Central",
        primary: "#ce1141",
        secondary: "#000",
    },
    CLE: {
        name: "Cleveland Cavaliers",
        conference: "Eastern",
        division: "Central",
        primary: "#6f263d",
        secondary: "#ffb81c",
    },
    DET: {
        name: "Detroit Pistons",
        conference: "Eastern",
        division: "Central",
        primary: "#ed174c",
        secondary: "#006bb6",
    },
    IND: {
        name: "Indiana Pacers",
        conference: "Eastern",
        division: "Central",
        primary: "#002d62",
        secondary: "#fdbb30",
    },
    MIL: {
        name: "Milwaukee Bucks",
        conference: "Eastern",
        division: "Central",
        primary: "#00471b",
        secondary: "#eee1c6",
    },
    ATL: {
        name: "Atlanta Hawks",
        conference: "Eastern",
        division: "Southeast",
        primary: "#e03a3e",
        secondary: "#c1d32f",
    },
    CHA: {
        name: "Charlotte Hornets",
        conference: "Eastern",
        division: "Southeast",
        primary: "#1d1160",
        secondary: "#00788c",
    },
    MIA: {
        name: "Miami Heat",
        conference: "Eastern",
        division: "Southeast",
        primary: "#98002e",
        secondary: "#f9a01b",
    },
    ORL: {
        name: "Orlando Magic",
        conference: "Eastern",
        division: "Southeast",
        primary: "#0057b8",
        secondary: "#c2ccd2",
    },
    WAS: {
        name: "Washington Wizards",
        conference: "Eastern",
        division: "Southeast",
        primary: "#002b5c",
        secondary: "#e31837",
    },
    DEN: {
        name: "Denver Nuggets",
        conference: "Western",
        division: "Northwest",
        primary: "#00285e",
        secondary: "#ffc72c",
    },
    MIN: {
        name: "Minnesota Timberwolves",
        conference: "Western",
        division: "Northwest",
        primary: "#0c2340",
        secondary: "#236192",
    },
    OKC: {
        name: "Oklahoma City Thunder",
        conference: "Western",
        division: "Northwest",
        primary: "#007ac1",
        secondary: "#ef3b24",
    },
    POR: {
        name: "Portland Trail Blazers",
        conference: "Western",
        division: "Northwest",
        primary: "#e03a3e",
        secondary: "#000",
    },
    UTA: {
        name: "Utah Jazz",
        conference: "Western",
        division: "Northwest",
        primary: "#002b5c",
        secondary: "#f9a01b",
    },
    GSW: {
        name: "Golden State Warriors",
        conference: "Western",
        division: "Pacific",
        primary: "#006bb6",
        secondary: "#fdb927",
    },
    SAC: {
        name: "Sacramento Kings",
        conference: "Western",
        division: "Pacific",
        primary: "#5a2d81",
        secondary: "#63727a",
    },
    LAC: {
        name: "Los Angeles Clippers",
        conference: "Western",
        division: "Pacific",
        primary: "#ed174c",
        secondary: "#006bb6",
    },
    LAL: {
        name: "Los Angeles Lakers",
        conference: "Western",
        division: "Pacific",
        primary: "#552583",
        secondary: "#ffc72c",
    },
    PHX: {
        name: "Phoenix Suns",
        conference: "Western",
        division: "Pacific",
        primary: "#1d1160",
        secondary: "#e56020",
    },
    SAS: {
        name: "San Antonio Spurs",
        conference: "Western",
        division: "Southwest",
        primary: "#000",
        secondary: "#c4ced4",
    },
    DAL: {
        name: "Dallas Mavericks",
        conference: "Western",
        division: "Southwest",
        primary: "#00538c",
        secondary: "#b8c4ca",
    },
    MEM: {
        name: "Memphis Grizzlies",
        conference: "Western",
        division: "Southwest",
        primary: "#6189b9",
        secondary: "#00285e",
    },
    NOP: {
        name: "New Orleans Pelicans",
        conference: "Western",
        division: "Southwest",
        primary: "#002b5c",
        secondary: "#e31837",
    },
    HOU: {
        name: "Houston Rockets",
        conference: "Western",
        division: "Southwest",
        primary: "#ce1141",
        secondary: "#000",
    },
};
