import "../css/dailyCounter.css";

function DailyCounter(props) {
    return (
        <div className="daily-counter-container">
            <div className="daily-countdown">Next game: {props.countdown}</div>
            <div className="daily-day fancy-text-shadow">Day {props.day}</div>
        </div>
    );
}

export default DailyCounter;
