import { GameContext } from "./GameProvider";
import { useContext } from "react";

function GuessHistory(props) {
    const gameContext = useContext(GameContext);
    return (
        <div className="guess-history-container">
            <h2>Guesses</h2>
            <div className="guess-history">
                {gameContext.guessHistory.map((guess, index) => {
                    return (
                        <div className="guess-history-item" key={index}>
                            <img className="x-guess" src="x.png" alt="" />
                            <p>{guess}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default GuessHistory;
