import "../css/autocomplete.css";
import "../css/guesser.css";
import { useContext, useEffect } from "react";
import { autocomplete } from "../autocomplete";
import DataWarning from "./DataWarning";
import { playerOptions, teamOptions } from "./GameProvider";
import { GameContext } from "./GameProvider";
import GuessHistory from "./GuessHistory";
import { maxGuesses } from "./archive/Unlimited";

function Guesser(props) {
    const gameContext = useContext(GameContext);

    useEffect(() => {
        const options =
            gameContext.gameMode === "Team" ? teamOptions : playerOptions;
        const inputElement = document.querySelector(
            `.guesser-${gameContext.gameMode} .autocomplete-me`
        );
        const container = document.querySelector(
            `.guesser-${gameContext.gameMode}`
        );

        autocomplete(inputElement, options, container);
        // console.log(inputElement, options.length);
    }, [gameContext.gameMode]);

    return (
        <div className={"guesser guesser-" + gameContext.gameMode}>
            <DataWarning gameMode={gameContext.gameMode} />
            <form autoComplete="off" className="autocomplete guesser-form">
                <input
                    className={"guesser-input autocomplete-me"}
                    type="text"
                    value={props.guessValue}
                    onInput={(e) => props.setGuessValue(e.target.value)}
                    placeholder={`Guess ${
                        gameContext.guessesMade + 1
                    }/${maxGuesses}`}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            props.guessButtonRef.current.click();
                        }
                    }}
                />
            </form>

            <button
                onClick={() => gameContext.makeGuess(props.guessValue, false)}
                className="disabled guess-button"
                ref={props.guessButtonRef}
            >
                Guess
            </button>

            {gameContext.guessHistory.length > 0 && <GuessHistory />}
        </div>
    );
}

export default Guesser;
