import { useContext } from "react";
import { GameContext } from "./GameProvider";
import { NavLink } from "react-router-dom";
import { setSavedGameMode } from "../utils";

function NewGameButton(props) {
    const gameContext = useContext(GameContext);
    return (
        <div className="new-game-container">
            <button
                className={`new-game-button ${
                    gameContext.gameType === "Unlimited"
                        ? "tertiary-bg"
                        : "disabled-game-button"
                }`}
                onClick={
                    gameContext.gameType === "Unlimited"
                        ? props.resetBoard
                        : null
                }
            >
                {`${
                    gameContext.gameType === "Unlimited"
                        ? "New Game"
                        : `New ${gameContext.gameMode.toLowerCase()} tomorrow`
                }`}
            </button>

            <button
                className="new-game-button new-other-game-button tertiary-bg"
                onClick={() => {
                    setSavedGameMode(
                        gameContext.gameMode === "Team" ? "Player" : "Team"
                    );
                    gameContext.setGameMode(
                        gameContext.gameMode === "Team" ? "Player" : "Team"
                    );
                }}
            >
                Guess the {gameContext.gameMode === "Team" ? "Player" : "Team"}
            </button>

            <NavLink
                to={gameContext.gameType === "Unlimited" ? "/daily" : "/"}
                className="new-game-button new-other-page-game-button"
            >
                {`Play ${
                    gameContext.gameType === "Unlimited" ? "Daily" : "Unlimited"
                }`}
            </NavLink>
        </div>
    );
}

export default NewGameButton;
