function PlayerTeamIcon(props) {
    return props.team === "basketball_question_mark" ? (
        <div className="player-mystery-container">
            <img
                className={`player-silhouette ${
                    props.darkSilhouette ? "dark-img" : ""
                } ${!props.showSilhouettes ? "hide-silhouette" : ""}`}
                src={props.headshot_url}
                alt={props.team}
                draggable="false"
            />
            <img
                className={`player-team-icon ${
                    props.showSilhouettes &&
                    props.team === "basketball_question_mark"
                        ? "hide-mystery-ball"
                        : ""
                }`}
                src={"/team-logos/" + props.team + ".png"}
                alt={props.team}
                draggable="false"
            />
        </div>
    ) : (
        <img
            className={`player-team-icon ${
                (props.show_silhouettes &&
                    props.team === "basketball_question_mark" &&
                    "hide-mystery-ball") ||
                ""
            }`}
            src={"/team-logos/" + props.team + ".png"}
            alt={props.team}
            draggable="false"
        />
    );
}

export default PlayerTeamIcon;
