import "../css/gameCycleDisplay.css";
import { teamMap } from "../maps";
import Guesser from "./Guesser";
import NewGameButton from "./NewGameButton";
import playerHistories from "../res/players.json";
import { lastElement } from "../utils";
import { useContext, useState } from "react";
import { GameContext } from "./GameProvider";
import { shareText } from "../utils";

function GameCycleDisplay(props) {
    const gameContext = useContext(GameContext);
    const [canCopy, setCanCopy] = useState(true);

    const handleShareScoreClick = (e) => {
        if (navigator && navigator.share) {
            navigator
                .share({
                    title: "Larry Birdle 3",
                    text: shareText(gameContext),
                })
                .then(() => console.log("Shared successfully"))
                .catch((error) => console.log("Error sharing:", error));
        } else {
            if (canCopy) {
                navigator.clipboard
                    .writeText(shareText(gameContext))
                    .then(() => {
                        console.log("Copied to clipboard");
                        setCanCopy(false);
                        e.target.textContent = "Copied!";
                        setTimeout(() => {
                            setCanCopy(true);
                            e.target.textContent = "Share your score";
                        }, 2000);
                    })
                    .catch((error) =>
                        console.log("Error copying to clipboard:", error)
                    );
            }
        }
    };

    const renderGameOver = () => {
        return (
            <div className="game-cycle-display lose">
                <h2 className="game-cycle-title">Game Over!</h2>
                <p className="game-cycle-body">
                    {gameContext.gameMode === "Team"
                        ? `The correct team was the ${
                              teamMap[gameContext.currentTeam].name
                          }! (lineup ${gameContext.currentTeamIndex})`
                        : `The correct player was ${
                              gameContext.currentPlayer
                          } on the ${
                              teamMap[
                                  lastElement(
                                      playerHistories[gameContext.currentPlayer]
                                          .mergedTeams
                                  ).split("=")[1]
                              ].name
                          }!`}
                </p>
                <button
                    className="new-game-button share-score-button"
                    onClick={(e) => {
                        handleShareScoreClick(e);
                    }}
                >
                    Share your score
                </button>
                <NewGameButton resetBoard={props.resetBoard} />
            </div>
        );
    };

    const renderCorrect = () => {
        return (
            <div className="game-cycle-display win">
                <h2 className="game-cycle-title">Correct!</h2>
                <p className="game-cycle-body">
                    {gameContext.gameMode === "Team"
                        ? `The team was the ${
                              teamMap[gameContext.currentTeam].name
                          }! (lineup ${gameContext.currentTeamIndex})`
                        : `The player was ${gameContext.currentPlayer} on the ${
                              teamMap[
                                  lastElement(
                                      playerHistories[gameContext.currentPlayer]
                                          .mergedTeams
                                  ).split("=")[1]
                              ].name
                          }!`}
                </p>
                <button
                    className="new-game-button share-score-button"
                    onClick={(e) => {
                        handleShareScoreClick(e);
                    }}
                >
                    Share your score
                </button>
                <NewGameButton resetBoard={props.resetBoard} />
            </div>
        );
    };

    const renderPlaying = () => {
        return (
            <Guesser
                guessValue={props.guessValue}
                setGuessValue={props.setGuessValue}
                guessButtonRef={props.guessButtonRef}
            />
        );
    };

    switch (props.gameState) {
        case "lose":
            return renderGameOver();
        case "win":
            return renderCorrect();
        case "playing":
            return renderPlaying();
        default:
            return null;
    }
}

export default GameCycleDisplay;
